import * as React from "react"
import EmailLink from "../components/email-link"

import Layout from "../components/layout"

const IndexPage = () => {
  return (
    <Layout title="Contact us">
      <main className="mt-8 pt-8 mb-16">
        <h1 className="text-4xl tracking-tight font-extrabold sm:text-5xl md:text-6xl">
          Contact us
        </h1>
        <h2 className="mt-8 mb-8">Get in touch</h2>
        <section className="sm:grid grid-flow-col auto-cols-auto">
          <div className="">
            <h3>Telephone</h3>
            <p>
              <big>
                <a href="tel:03330904669">0333 090 4669</a>
              </big>
            </p>
            <p>Mon-Fri: 9am-5pm</p>
          </div>
          <div className="sm:hidden">
            <div className="border-t-2 border-yellow-500 my-12"></div>
          </div>
          <div className="">
            <h3>Email</h3>
            <p>
              <big>
                <EmailLink />
              </big>
            </p>
          </div>
          <div className="sm:hidden">
            <div className="border-t-2 border-yellow-500 my-12"></div>
          </div>
          <div>
            <h3>Address</h3>
            <p>
              <strong>SB Relocations</strong>
              <br />
              Windsor
              <br />
              Berkshire
            </p>
            <a
              href="https://www.google.co.uk/maps/place/Windsor/@51.4773308,-0.6431535,14z/data=!3m1!4b1!4m5!3m4!1s0x48767a0c48323997:0x4a6d76d3ed7d32b6!8m2!3d51.4817279!4d-0.613576"
              target="_blank"
              rel="noreferrer"
              className=""
            >
              Get Directions
            </a>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default IndexPage
